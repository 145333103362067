<template>
  <v-container>
    <h4 class="mt-10">المعلومات الأساسية</h4>

    <v-row v-if="edit_loader">
      <v-col v-for="(a, i) in 10" :key="i" cols="6" md="3">
        <v-skeleton-loader type="card-heading"></v-skeleton-loader>
      </v-col>
    </v-row>

    <v-form v-if="!edit_loader" ref="form" class="mt-4">
      <v-row>
        <v-col cols="6" md="3">
          <v-text-field
            @keypress="name_exist = false"
            :rules="rules.name"
            v-model="store.store_name"
            solo
          >
            <template slot="label">
              <strong class="red--text">*</strong>
              اسم المحل
            </template>
          </v-text-field>
        </v-col>
        <!--/ Store name  -->

        <v-col cols="6" md="3">
          <v-text-field :rules="rules.country" solo v-model="store.country">
            <template slot="label">
              <strong class="red--text">*</strong>
              البلد
            </template>
          </v-text-field>
        </v-col>
        <!--/Country  -->

        <v-col cols="6" md="3">
          <v-text-field
            @keypress="phone_exist = false"
            :rules="rules.phone"
            solo
            v-model="store.phone_number"
            type="number"
          >
            <template slot="label">
              <strong class="red--text">*</strong>
              رقم صاحب المحل
            </template>
          </v-text-field>
        </v-col>
        <!--/Phone number  -->

        <v-col cols="6" md="3">
          <v-text-field
            :rules="$route.params.id ? [] : rules.password"
            solo
            type="password"
            v-model="store.password"
          >
            <template slot="label">
              <strong v-if="!$route.params.id" class="red--text">*</strong>
              الرقم السرى
            </template>
          </v-text-field>
        </v-col>

        <v-col cols="12" class="row">
          <v-col cols="6" md="3" v-if="$route.params.id">
            <v-text-field
              solo
              v-model="store.created_at"
              readonly
              label=" تاريخ الانشاء"
              disabled
            >
            </v-text-field>
          </v-col>

          <v-col cols="6" md="3">
            <v-menu
              ref="menu"
              v-model="menu1"
              :close-on-content-click="false"
              :return-value.sync="store.expiration_date"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="store.expiration_date"
                  label="تاريخ الانتهاء"
                  prepend-icon=""
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  solo
                >
                  <template slot="append">
                    <v-icon>mdi-calendar</v-icon>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                v-model="store.expiration_date"
                no-title
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu1 = false">
                  الغاء
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(store.expiration_date)"
                >
                  نعم
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="6" md="3">
            <v-text-field
              @keypress="phone_exist = false"
              solo
              v-model="store.commission"
            >
              <template slot="label">
                <strong class="red--text">*</strong>
                العمولة
              </template>
            </v-text-field>
          </v-col>
          <!--/Phone number  -->
        </v-col>

        <v-col cols="3">
          <v-text-field
            class="color"
            label="اللون الاساسي"
            type="color"
            v-model="store.primaryColor"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            class="color"
            label="اللون الثانوي"
            type="color"
            v-model="store.secondaryColor"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            class="color"
            label="لون خلفية المينيو"
            type="color"
            v-model="store.bodyColor"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            class="color"
            label="لون خلفية المنتج"
            type="color"
            v-model="store.cardColor"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            class="color"
            label="لون بيانات الهيدر"
            type="color"
            v-model="store.headerColor"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-select
            solo
            :rules="[(v) => !(v.length == 0) || 'مطلوب']"
            multiple
            v-model="store.languages"
            :items="languages"
            item-text="lang_name"
            item-value="id"
          >
            <template slot="label">
              <strong class="red--text">*</strong>
              اللغات
            </template>
          </v-select>
        </v-col>
        <!--/ password  -->

        <v-col cols="6" v-if="$route.params.id">
          <v-text-field
            solo
            readonly
            class="read_only"
            :value="`https://menyo.co/${$route.params.id}`"
          >
            <template slot="append">
              <v-btn @click="copy()" color="primary" icon>
                <v-icon :color="copied ? 'success' : 'black'">
                  {{ copied ? "check_circle" : "content_copy" }}
                </v-icon>
              </v-btn>

              <v-btn
                target="_blank"
                :href="`https://menyo.co/${$route.params.id}`"
                color="primary"
                icon
              >
                <v-icon> visibility </v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-col>
        <!--/ password  -->
        <v-col cols="12">
          <h4 class="mt-10">معلومات اضافية</h4>
        </v-col>
        <!--/ title -->
        <v-col cols="6" md="3">
          <v-file-input
            accept="image/*"
            prepend-icon=""
            prepend-inner-icon="attach_file"
            solo
            v-model="store.logo"
          >
            <template slot="label"> شعار المحل </template>
          </v-file-input>
        </v-col>
        <!--/Store Logo-->

        <v-col cols="6" md="3">
          <v-text-field solo v-model="store.instagram">
            <template slot="label"> انستاجرام </template>
          </v-text-field>
        </v-col>
        <!--/Instagram -->

        <v-col cols="6" md="3">
          <v-text-field type="number" solo v-model="store.whatsapp">
            <template slot="label"> واتساب </template>
          </v-text-field>
        </v-col>
        <!--/Whatsapp -->

        <v-col cols="6" md="3">
          <v-text-field solo v-model="store.phone">
            <template slot="label"> رقم الهاتف </template>
          </v-text-field>
        </v-col>
        <!--/Phone -->

        <v-col cols="6" md="3">
          <v-text-field type="number" solo v-model="store.sec_phone">
            <template slot="label"> رقم الهاتف2 </template>
          </v-text-field>
        </v-col>
        <!--/Second phone number -->

        <v-col cols="6" md="3">
          <v-text-field solo v-model="store.address">
            <template slot="label"> عنوان المحل </template>
          </v-text-field>
        </v-col>
        <!--/Store address -->

        <v-col cols="6" md="3">
          <v-file-input solo v-model="store.video">
            <template slot="label"> فيديو</template>
          </v-file-input>
        </v-col>
        <!--/Store address -->

        <v-col cols="6" md="3">
          <v-select
            solo
            :items="themes"
            item-text="text"
            item-value="value"
            v-model="store.theme"
          >
            <template slot="label"> عنوان المحل </template>
          </v-select>
        </v-col>
        <!--/Store address -->

        <v-col cols="12">
          <h4 class="mt-10">من نحن</h4>
        </v-col>
        <!--/ title -->
        <v-col cols="12">
          <v-textarea solo v-model="store.about">
            <template slot="label"> من نحن </template>
          </v-textarea>
        </v-col>
        <!--/Store Logo-->

        <v-col cols="12">
          <h4 class="mt-10">الصلاحيات</h4>
          <v-row>
            <v-col>
              <v-checkbox
                v-model="notes"
                @change="select_per($event, 'notes')"
                label="الملاحظات"
              ></v-checkbox>
            </v-col>
            <v-col>
              <v-checkbox
                v-model="additions"
                @change="select_per($event, 'additions')"
                label="الأضافات"
              ></v-checkbox>
            </v-col>
            <v-col>
              <v-checkbox
                v-model="orders"
                @change="select_per($event, 'orders')"
                v
                label="الطلبات"
              ></v-checkbox>
            </v-col>
            <v-col>
              <v-checkbox
                v-model="tables"
                @change="select_per($event, 'tables')"
                v
                label="الطاولات"
              ></v-checkbox>
            </v-col>
            <v-col>
              <v-checkbox
                v-model="video"
                @change="select_per($event, 'video')"
                v
                label="فيديو"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12 mb-10">
          <v-btn
            @click="save()"
            :loading="save_loader"
            :disabled="save_loader"
            color="primary"
            width="280"
            height="60"
            class="save"
            >حفظ</v-btn
          >
        </v-col>
      </v-row>
      <!--/row -->
    </v-form>
    <v-snackbar color="success" left v-model="snackbar_success">
      <p>تم حفظ المحل بنجاح</p>
      <template v-slot:action>
        <v-icon color="white" class="ms-3">check_circle</v-icon>
      </template>
    </v-snackbar>

    <v-snackbar color="error" left v-model="snackbar_error">
      <p>برجاء مراجعة البيانات</p>
      <template v-slot:action>
        <v-icon color="white" class="ms-3">cancel</v-icon>
      </template>
    </v-snackbar>

    <!--/ form -->
  </v-container>
</template>

<script>
export default {
  name: "store_page",
  data() {
    return {
      themes: [
        {
          text: "الافتراضي",
          value: "default",
        },
        {
          text: "فئات",
          value: "category",
        },
        {
          text: "سكرول",
          value: "scroll",
        },
      ],
      copied: false,
      edit_loader: false,
      snackbar_success: false,
      snackbar_error: false,
      languages: [],
      phone_exist: false,
      name_exist: false,
      menu1: false,
      rules: {
        name: [
          (v) => !!v || "حقل مطلوب",
          (v) => /^.{4,}$/.test(v) || "يجب ان لا يقل عن 4 حروف او ارقام",
          (v) => !this.name_exists || "اسم المحل موجود مسبقا",
        ],
        country: [(v) => !!v || "حقل مطلوب"],
        password: [
          (v) => /^.{8,}$/.test(v) || "يجب ان لا يقل عن 8 حروف او ارقام",
          (v) => !!v || "حقل مطلوب",
        ],
        phone: [
          (v) => !!v || "حقل مطلوب",
          (v) => !this.phone_exists || "رقم  الهاتف موجود مسبقا",
        ],
      },
      notes: false,
      additions: false,
      orders: false,
      tables: false,
      video: false,
      store: {
        commission: 0,
        created_at: "",
        store_name: "",
        country: "",
        phone_number: "",
        password: "",
        video: null,
        instagram: "",
        whatsapp: "",
        phone: "",
        sec_phone: "",
        address: "",
        logo: null,
        about: "",
        primaryColor: "#7382f2",
        secondaryColor: "#717171",
        bodyColor: "#ffffff",
        cardColor: "#ffffff",
        headerColor: "#ffffff",
        languages: [],
        theme: "default",
        expiration_date: new Date(
          Date.now() - new Date().getTimezoneOffset() * (4380 * 60000)
        )
          .toISOString()
          .substr(0, 10),
        permissions: [],
      },
      save_loader: false,
    };
  },

  computed: {
    phone_exists() {
      return this.phone_exist ? true : false;
    },
    name_exists() {
      return this.name_exist ? true : false;
    },
  },
  methods: {
    get_langs() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "languages",
            method: "get",
          },
        })
        .then((res) => {
          this.data_loaded = true;
          this.languages = Object.assign([], res.data.data);
        });
    },
    save() {
      if (this.$refs.form.validate() && !this.save_loader) {
        this.save_loader = true;
        const formData = new FormData();
        Object.entries(this.store).forEach((e, i) => {
          if (e[0] != "permissions" && e[0] != "languages") {
            formData.append(e[0], e[1]);
          } else if (e[0] == "permissions") {
            Object.entries(this.store.permissions).forEach((s) => {
              formData.append(`permissions[${s[0]}]`, s[1]);
            });
          } else if (e[0] == "languages") {
            this.store.languages.forEach((l, x) => {
              formData.append(`languages[${x}]`, l);
            });
          }
        });
        this.$store
          .dispatch("public__request", {
            config: {
              url: this.$route.params.id
                ? `Stores/${this.$route.params.id}`
                : `Stores`,
              method: "post",
            },
            data: formData,
          })
          .then((res) => {
            this.snackbar_success = true;
            this.$router.push("/Stores");
            console.log(res);
          })
          .catch((err) => {
            console.log(err.response);
            this.snackbar_error = true;
            if (err.response.data.data.phone_number) {
              this.$set(this, "phone_exist", true);
            }
            if (err.response.data.data.store_name) {
              this.$set(this, "name_exist", true);
            }
            this.$refs.form.validate();
            this.save_loader = false;
          });
      }
    },
    edit_store() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: `Stores/${this.$route.params.id}`,
            method: "get",
          },
        })
        .then((res) => {
          let data = res.data.data;
          Object.entries(data).forEach((e, i) => {
            if (e[0] != "logo") {
              this.store[e[0]] = e[1] != "null" ? e[1] : "";
            }
          });
          data.permissions.forEach((e) => {
            this[e] = true;
          });
          this.edit_loader = false;
        })
        .catch((err) => {
          this.$router.push("/stores");
        });
    },
    copy() {
      if (!this.copied) {
        let link = `https://menyo.co/${this.$route.params.id}`;
        let vm = this;
        navigator.clipboard.writeText(link).then(() => {
          this.copied = true;
          setTimeout(() => {
            this.copied = false;
          }, 2000);
        });
      }
    },
    select_per(ev, value) {
      if (ev) {
        this.store.permissions.push(value);
      } else {
        let ind = this.store.permissions.findIndex((e) => e == value);
        this.store.permissions.splice(ind, 1);
      }
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.edit_loader = true;
      this.edit_store();
    }
    this.get_langs();
  },
};
</script>

<style lang="scss" scoped>
.save {
  font-size: 20px;
}
::v-deep {
  .v-skeleton-loader__heading {
    width: 80% !important;
    height: 40px;
  }
  .read_only {
    .v-input__slot {
      background: #f0f0f0 !important; /*  */
    }
  }
  .color.v-text-field input {
    padding: 3px 0 0;
  }
  .color.v-text-field > .v-input__control > .v-input__slot:after,
  .color.v-text-field > .v-input__control > .v-input__slot:before {
    display: none !important;
  }
}
</style>
